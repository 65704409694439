*{
  margin: 0;padding: 0;box-sizing: border-box;
 
  
}
::-webkit-scrollbar {
  width: 0px; /* Width of the scroll bar */
  height: 0px;
}
nav{
  height: 90px;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px !important;
 background-color: white;
 /* padding-bottom: px; */
 color: black !important;
 z-index: 10;
}
.bnav{
  color: rgb(0, 0, 0);
}
nav ul{
  display: flex;
  flex-direction: row;
  gap: 30px;

}
nav ul li{
  /* color: rgba(55, 71, 79, 1); */
  font-family: 'Rubik', sans-serif;
font-size: 18px;
font-weight: 400;
list-style: none;
color: white !important;

}
.banner-section{
  display: flex;
  flex-direction: row;
  /* height: 70vh; */
  /* padding-top: 100px; */
}
.banner-section-left{
  padding: 50px;
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.banner-section-right{
  width: 50%;
  background-image: url(../src/Assets/g12.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner-section h1{
  width: 600px;
border-left: 5px solid rgba(106, 215, 229, 1);
font-family: 'Syne', sans-serif;
font-size: 32px;
color: rgba(55, 71, 79, 1);
padding-left: 20px;
}
.banner-section h1 span{
  font-size: 60px;
}
.slider{
  height: 100px;
  background-color: black;
  font-size: 24px;
  color: white;
font-family: 'Syne', sans-serif;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;

}
.slider ul li{
  list-style: none;
}
.slider ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  
}
.slider {
  width: 100%;
  overflow: hidden;
}

.slider ul {
  white-space: nowrap;
  padding: 0;
  margin: 0;
  display: inline-block;
  animation: scrolling 15s linear infinite;
}

.slider li {
  display: inline-block;
  padding: 0 20px;
  font-size: 18px;
}

@keyframes scrolling {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}

.about{
  display: flex;
  flex-direction: row;
}
.about-left{
  width: 50%;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;padding-bottom: 50px;
}
.about-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line{
  height: 5px;
  width: 69px;
  background-color: rgba(106, 215, 229, 1);
}
.about-left h1{
  font-size: 32px;
font-family: 'Syne', sans-serif;
font-weight: 600;
}
.about-left p{
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
color: rgba(111, 111, 111, 1);
  font-weight: 400;
}
.about-left h2 span{
  font-weight: 500;
}
.about-left h2{
  font-size: 32px;
  font-weight: 700;
  margin-top: 5px;
font-family: 'Syne', sans-serif;

}
.about-left h3{
font-size: 18px;
font-weight: 400;
color: rgba(111, 111, 111, 1);

font-family: 'Rubik', sans-serif;


}
.brand-slider{

  display: flex;
  flex-direction: row;
  gap: 50px;

}
.brand-slider-section{
  height: 202px;
  background-color: rgba(247, 247, 250, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.offer{
  background-color: rgba(247, 254, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offer h1{
  font-size: 40px;
  font-weight: 500;
  font-family: 'Syne', sans-serif;



}
.ser-card{
  Width:
333px;
Height:
287px;
background-color: rgba(250, 250, 250, 1);
border-radius: 7px;
padding: 20px;
display: flex;
padding-top: 40px;
flex-direction: column;
}
.sys{
  background-color: rgba(250, 250, 250, 1);
  height: 58px;
  width: 58px;
  border-radius: 50%;
  border: 1px solid rgba(106, 215, 229, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.ser-card h1{
  font-size: 20px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}

.ser-card p{
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.ser-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
padding-bottom: 30px;
}
.ser-cards-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

}
.persent-sectiom{
  background-color: rgba(247, 247, 250, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 100px;
}
.persent-crds{
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.circle{
  height: 190px;
  width: 190px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.circle h2{
  font-size: 32px;
  font-weight: 700;
  font-family: 'Rubik', sans-serif;

}
.persent-crds h3{
  font-size: 20px;
  font-weight: 700;
font-family: 'Syne', sans-serif;

}
.persent-crds p{
  font-size: 18px;
  font-weight: 400;
font-family: 'Syne', sans-serif;
color: rgba(63, 63, 63, 1);
}
.ourcustomer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lang{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
.ourcustomer h1{
  font-size: 32px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.ourcustomer h1 span{
  font-weight: 400;
}
.testimonials{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* padding-left: 450px;
padding-right: 450px; */
text-align: center;
margin-top: 50px;
}
.test{
  /* height: 500px; */
  width: 100%;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 
}
.link{
  color: black;
  text-decoration: none;
}
.test1{
  /* height: 500px; */
  width: 500px;
  padding-bottom: 50px;
 
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  /* display: block;
 
  object-fit: cover; */
}

.swiper {
  width: 100%;
  /* margin-left: auto;
  margin-right: auto; */
}
.review{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.testimonials h1{
font-size: 35px;
font-weight: 700;
font-family: 'Syne', sans-serif;

}
.testimonials h1 span{
  font-weight: 500;
}
.review p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
color: rgba(113, 128, 150, 1);
}
.coma1{
  margin-top: -100px;
}
.coma2{
  margin-top: 70px;
}
.review-profile-card{
  /* width: 123px; */
  height: 193px;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.review-profile-card h3{
  font-size: 18px;
  font-weight: 700;
  color: #08BBBF;
  font-family: 'Inter', sans-serif;
  margin-top: 10px;
}
.review-profile-card p{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin-top: 5px;

}
.footer-top{
  background-color: rgba(0, 37, 42, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(203, 213, 224, 0.23);
}
.footer-top ul li{
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  margin-top: 15px;
}
.footer-top ul {
  font-size: 18px;
  font-weight: 700;
font-family: 'Syne', sans-serif;


}
.footer-top h3{
  font-size: 18px;
  font-weight: 700;
font-family: 'Syne', sans-serif;
}
.footer-top p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;

}
.foot-bottom{
 color: rgba(155, 155, 155, 1);
  background-color: rgba(0, 37, 42, 1);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}
.about-section{
  background-color: rgba(106, 215, 229, 1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 300px;
  
}
.about-section h1{
  font-size: 52px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.about-section p{
  width: 80%;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  text-align: center;

}
.newstatic{
  position: absolute;
  top: 450px;
 
}
.about-who-we-are{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
 
}
.about-who-we-are h1{
  font-size: 32px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.about-who-we-are p{
  color: rgba(111, 111, 111, 1);
  font-family: 'Rubik', sans-serif;

font-size: 18px;
font-weight: 400;
margin-top: 20px;
}
.our-vision-section{

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 50px;
}
.our-vision{

  width: 90%;
 border: 1px solid rgba(106, 215, 229, 1);
 border-radius: 16px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-top: 200px;
 text-align: center;
 padding-left: 70px;
 padding-right: 70px;
 padding-bottom: 100px;
 box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);

}
.about-bnner2{
margin-top: -200px;
}
.our-vision h1{
  font-size: 32px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
margin-top: 20px;
}
.our-vision p{
  font-size: 18px;
  font-weight: 400;
  color: rgba(111, 111, 111, 1);
  font-family: 'Rubik', sans-serif;

}
.intro-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  

}
.intro-section h3{
  font-family: 'Rubik', sans-serif;
font-size: 15px;
font-weight: 500;
}
.intro-section h1{
  font-size: 40px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}

.casestudy-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.casestudy{
  width: 85%;
  background-color: rgba(233, 247, 255, 1);
  /* height: 300px; */
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
}
.casestudy-left, .casestudy-right{
  width: 45%;
  /* height: 100%; */
}
.casestudy-left img{
  height: 100%;
  width: 100%;
  border-radius: 15px;

}

.casestudy-right{
  background-color: white;
  /* height: 460px; */
  padding: 30px;
  border-radius: 10px;
 
}
.casestudy-right button{
  height: 64px;
  width: 221px;
  background-color: rgba(106, 215, 229, 1);
  border-radius: 7px;
  color: white;
  font-size: 18px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.casestudy-right h2{
  font-size: 25px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.casestudy-right p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
color: rgba(85, 85, 85, 1);
}
#pink{
  background-color: rgba(255, 219, 212, 1) !important;
}
#yellow{
  background-color: rgba(255, 243, 202, 1);
}
#orange{
  background-color: rgb(255, 227, 202);
}
.our-servies-section{
  width: 100%;

  display: flex;
  flex-direction: row;
  
}
.our-servies-section-left , .our-servies-section-right{
  width: 50%;
  height: 100%;

}
.our-servies-section-left{
  padding-left: 150px;
  padding-top: 100px;


}
.our-servies-section-right{
  padding-right: 150px;
  padding-top: 100px;

}
.our-servies-section-left h2 , .our-servies-section-right h2{
  font-size: 24px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.our-servies-section-left p , .our-servies-section-right p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;

}
.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center h1{
  font-size: 52px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.center h2{
font-size: 40px;
font-weight: 700;
font-family: 'Syne', sans-serif;

}
.center p{
  font-size: 15px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;

  
  }
.tab-container{
  padding: 50px;
}
.tabs {
display: flex;
flex-direction: row;
gap: 50px;
}
.tabs button{border: none;
background: none;
font-size: 40px;
font-weight: 700;
font-family: 'Syne', sans-serif;


}
.tab-content h1{
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
font-family: 'Syne', sans-serif;
color: black;

}
.tab-content h3{
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
margin-top: 10px;
margin-bottom: 10px;
color: black;
}
.tab-content p{
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  color: rgba(128, 128, 128, 1);
}
.box{
  width: 380px;
  height: 270px;
  background-color: rgba(106, 215, 229, 1);
  border-radius: 7px;
  padding: 30px;
}
.box button{
  padding: 6px 26px 6px 26px;
  background-color: black;
  color: white;
  border-radius: 59px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;

}
.box hr{
  color: white;
  background-color: white;
}
.box h4{
  font-size: 25px;
  font-weight: 600;
  color: white;

}
.box p{
  font-size: 18px;
  font-weight: 400;
  color: white;

}
.mail{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: start;
}
.hr{
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
.box-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-family: 'Nunito Sans', sans-serif;


}
.form-section{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.form{
  width: 90%;
  /* height: 100px; */
  background-color: rgba(247, 254, 255, 1);
  border: 1px solid rgba(106, 215, 229, 1);
  border-radius: 15px;
  padding: 50px;
}
.inputs{
  width: 40%;

}
.inputs input{
  width: 100%;
  height: 64px;
  border-radius: 7px;
  border: 1px solid rgba(220, 220, 220, 1);
  display: flex;
  flex-direction: column;
}
.form-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.message{
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
}
.message input{
  border-radius: 7px;
  border: 1px solid rgba(220, 220, 220, 1);
  display: flex;
  flex-direction: column;
  height: 200px;
}
.lable{
  font-family: 'Nunito Sans', sans-serif;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}
.form-section button{
  padding: 16px 39px 16px 39px;
  background-color: rgba(106, 215, 229, 1);
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif;
border-radius: 7px;
}



@media(max-width:1300px){
  .banner-section{
    display: flex;
    flex-direction: row;
 
  }
  .banner-section-left{
    padding-left: 50px;
    margin-top: 30px;
    margin-bottom: 30px;

  }
  .banner-section h1{
    /* width: 500px; */
  }
}
@media(max-width:1200px){
  .banner-section-left{
    padding-left: 100px;
    background: none;
  }
  
}
@media(max-width:1070px){
  .banner-section-left{
    width: 70%;
    padding-left: 100px;
  }
}

@media(max-width:1140px){
  .about{
    display: flex;
    flex-direction: column-reverse;
  }
  .about-left , .about-right{
    width: 100%;
    padding: 20px;
  }
  .brand-slider {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent images from wrapping to the next line */
  margin-top: 30px;
  gap: 30px;
  padding-left: 20px;
  }
  
  .brand-slider img {
    max-width: 100%;
    height: auto;
    margin-right: 10px; /* Optional: Add some spacing between images */
  }
  .brand-slider::-webkit-scrollbar {
    display: none;  /* Hide scrollbar for Chrome and Safari */
  }
}
@media(max-width:600px){
  .about-right img{
    height: 250px;
  }
}
@media(max-width:420px){
  .about-right img{
    height: 200px;
  }
}
@media(max-width:1500px){
  .ser-cards-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  
  }
}
@media(max-width:768px){
  .ser-cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  
  }
  .persent-sectiom{
    gap: 20px;
  }
  .bnav{
    color: black;
  }
}
@media(max-width:600px){
  .persent-sectiom{
    flex-direction: column;
  }
  .persent-crds{
    width: 50%;
  }
  .circle{
    height: 100px;
    width: 100px;
  }
  .circle h2{
    font-size: 20px;
  }
  
}
.ourcustomer {
  overflow: hidden;
}

.scrolling-images {
  display: flex;
  animation: scrollAnimation 20s linear infinite;
}

@keyframes scrollAnimation {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

.lang img {
  max-width: 100px;
  height: auto;
  margin: 0 10px;
}
@media(max-width: 1300px){
.testimonials{
  padding-left: 100px;
  padding-right: 100px;
  
}
#abt-banner{
  height: 300px;
}
.about-who-we-are{
  margin-top: 170px;
}
.about-section{
  padding-bottom: 150px;
}
.intro-section{
  /* margin-top: 150px; */

}
}

@media(max-width: 520px){
  .testimonials{
    padding-left: 30px;
    padding-right: 30px;


  }
  .banner-section{
    padding-top: 100px !important;
  }

.prd-img{
  height: 300px;
}

  }
  .listes{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media(max-width: 1100px){
    /* .footer-top{
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
      justify-content: center;
      align-items: center;
    }
    .listes{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
    } */
  }
  @media(max-width: 900px){
   
    #abt-banner{
      height: 250px;
    }
    .about-bnner2{
      margin-top: -50px;
      }
      .about-who-we-are{
        margin-top: 100px;
      }
      .about-section{
        padding-bottom: 150px;
      }
      .newstatic{
        position: absolute;
        top: 500px;
       
      }
  }
  @media(max-width: 550px){
    #abt-banner{
      height: 180px;

    }
    .our-vision{
      padding-left: 15px;
      padding-right: 15px;
    }
    .about-who-we-are{
      margin-top: 10px;
    }
    .about-section{
      padding-bottom: 80px;
    }
    .newstatic{
      position: absolute;
      top: 550px;
     
    }
    .about-section h1{
      font-size: 52px;
      font-weight: 600;
    font-family: 'Syne', sans-serif;
    
    }
  }
  @media(max-width: 450px){
    #abt-banner{
      height: 150px;
    }
    .about-who-we-are{
      margin-top: 1px;
    }
    .about-section{
      padding-bottom: 80px;
    }
    .newstatic{
      position: absolute;
      top: 600px;
     
    }
  }
  @media(max-width: 410px){
    .newstatic{
      position: absolute;
 
     
    }
    .about-section p{
      width: 80%;
      font-size: 17px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
      text-align: center;
    
    }
  }
  @media(max-width: 380px){
    .newstatic{
      position: absolute;
 margin-top: 20px;
     
    }
  }
  @media(max-width: 1330px){
    .casestudy{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .casestudy-left, .casestudy-right{
      width: 100%;
      /* height: 100%; */
      height: auto;
      
    }
    .casestudy-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* align-items: center; */
      
    }

  }
  @media(max-width: 700px){
    .casestudy-left img{
      /* height: 250px; */
    }
    .al{
      text-align: center;
    }
  }
  @media(max-width: 1430px){
    .our-servies-section{
      width: 100%;
      /* height: 100vh; */
      display: flex;
      flex-direction: column;
    }
    .our-servies-section-left , .our-servies-section-right{
      width: 100%;
      height: auto;
      /* background-color: aqua; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* padding: 100px; */
    }
    .our-servies-section-left{
      padding-left: 20px;
      padding-top: 20px;
    
      padding-right: 1px;
    
    }
    .our-servies-section-right{
      padding-left: 10px;

      padding-right: 1px;
      padding-top: 20px;
    
    }
  }
  @media(max-width: 1200px){
    .box-section{
      display: flex;
      flex-direction: column;
    }
  }
  @media(max-width: 768px){
    .form-row{
      flex-direction: column;
      padding-left: 1px;
      padding-right: 1px;
    }
    .inputs{
      width: 100%;
    
    }
    .message{
      padding-left: 1px;
      padding-right: 1px;
    }
    .intro-section{
      /* margin-top: 100px; */
    
    }
  }

  nav {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    /* border-bottom: 1px solid rgba(55, 71, 79, 1); */
  }
  
  nav img {
    max-height: 70px;
    margin-bottom: 5px;
  }
  
  nav ul {
    list-style-type: none;
    display: flex;

  }
  .dropdown-content{
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  nav ul li {
    margin-right: 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
  font-family: 'Rubik', sans-serif;

  }
  
  .hamburger {
    font-size: 30px;
    cursor: pointer;
    display: none;
  }
  #hamburger{
    color: rgb(0, 0, 0) !important;
  }
  
  .hamburger.open {
    transform: rotate(90deg);
  }
  
  nav ul.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    text-align: center;
    padding-bottom: 20px;
  }
  
  nav ul.open li {
    margin: 10px 0;
  }
  
  @media only screen and (max-width: 950px) {
    nav ul {
      display: none;
      flex-direction: column;
      position: absolute;
      top:20px;
      left: 0;
      width: 100%;
      background-color: #fffdfd;
      text-align: center;
    }
  .dropdown-content{
    width: 100% !important;

  }
  .ul-containt-wo{
    width: 100% !important;

  }
    nav ul li {
      margin: 10px 0;
    }
  
    .hamburger {
      display: block;
    }
    .dropdown-content{
      margin-top: 15px !important;
    }
  }
  /* blog */
  .blog-card{
    height: 400px;
    width: 300px;
    border-radius: 7px;
    box-shadow: 1px -3px 17px -9px rgba(0,0,0,0.75);
  }
  .blog-card img{
    height: 300px;
    width: 100%;
  }
  .blog-card h2{
    font-size: 20px;
font-family: 'Syne', sans-serif;

font-weight: 700;
margin-top: 10px;
padding-left: 10px;
  }
  .blog-card p{
  font-family: 'Rubik', sans-serif;
font-size: 15px;
font-weight: 400;
margin-top: 5px;
padding-left: 10px;

  }
  .blog-card button{
    background: none;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
padding-left: 10px;

  }
  .blog-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* or justify-content: space-between; */
  }
  
  .blog-card {
    width: calc(25% - 20px); /* Adjust the width as needed */
    margin: 10px; /* Adjust the margin as needed */
    border: 1px solid #ccc; /* Add border or other styling as needed */
  
    /* Additional styling for card content */
  }
  
  @media screen and (max-width: 1200px) {
    .blog-card {
      width: calc(33.33% - 20px); /* Adjust the width for tablets */
    }
  }
  
  @media screen and (max-width: 768px) {
    .blog-card {
      width: calc(50% - 20px); /* Adjust the width for smaller tablets */
    }
  }
  
  @media screen and (max-width: 576px) {
    .blog-card {
      width: 100%; /* Full width for mobile */
    }
  }
  .center h3{
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    font-weight: 400;
  }
  .blog-detail{
    display: flex;

flex-direction: row;
justify-content: center;
align-items: center;  }
  .blog-detail-left{
    width: 100%;
    height: 100%;
padding: 30px;

  }
  .blog-detail-left img{
    height: 300px;
    width: 100%;

  }
  .blog-detail-right{

  }
  .blog-detail-left h1{
    font-size: 40px;
    font-family: 'Syne', sans-serif;
    
    font-weight: 700;
    margin-bottom: 30px;
  }
  .blog-detail-left p{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 400;
   

  }
  .blog-detail-left h3{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin-top: 20px;

    
  }
  .blog-detail-left button{
    background: none;
    border: 1px solid #ccc;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    letter-spacing: 5px;
    margin-top: 30px;
  }
  @media screen and (max-width: 1000px) {
    .blog-detail{
      display: flex;
      flex-direction: column;
    }
    .blog-detail-left , .blog-detail-right{
      width: 100%;
      height: 50%;
    }
  }
  .apply{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 100px;
  }
  .apply button{
    padding: 25px 38px 26px 38px;
    border-radius: 50px;
    background: none;
    border: 1px solid rgba(220, 220, 220, 1);
    font-family: 'Nunito Sans', sans-serif;
  
    font-size: 20px;
    font-weight: 600;
  }
  .contact-heading{
    padding-top: 130px !important;
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .contact-heading h1{
    font-size: 40px;
    font-weight: 700;
  font-family: 'Syne', sans-serif;
  }
  .contact-heading p{
    font-size: 25px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  .contact{
    display: flex;
    flex-direction: row;
  }
  .contact-left{
    width: 50%;
    padding: 50px;
  }
  .contact-left h2{
    font-size: 30px;
    font-weight: 700;
  font-family: 'Syne', sans-serif;
  }
  .contact-left p{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  .contact-right{
    width: 50%;
    padding: 50px;
  }
  .contact-form{
display: flex;
flex-direction: row;
justify-content: space-between;
  }
  .contact-form input{
    width: 95%;
    height: 40px;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid black;
    /* margin-top: 20px; */
  }
  .contact-forma input{
    width: 100%;
    height: 40px;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid black;
    /* margin-top: 20px; */


  }
  @media(max-width:768px){
    .contact-heading{
      flex-direction: column;
      padding: 20px;
    }
    .contact{
      flex-direction: column;

    }
    .contact-left,   .contact-right{
      width: 100%;
      padding: 20px;

    }
  }
  .sendcontact{
    padding: 10px 20px 10px 20px;
    background: none;
    border: 1px solid #ccc;
    border-radius: 7px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 700;
  font-family: 'Syne', sans-serif;
  transition: background-color 0.3s ease-in-out;
  }
  .sendcontact:hover{
    background-color: rgba(106, 215, 229, 1);
    border: 1px solid rgba(106, 215, 229, 1);
  }
  @media(max-width:768px){
    nav{
      /* padding: 20px; */
    }
    nav ul{
      display: none;
  
    }
    .banner-section-right{
      display: none;
    }
    .banner-section-left{
      height: auto;
      width: 100%;
      padding-bottom: 30px;
      padding-top: 30px;
      padding-left: 30px;
    }
    .banner-section h1{
      width: auto;
    }
  }
  @media(max-width:600px){
    .banner-section-left{
      height: auto !important;
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .slider{
      height: 70px;
    }

  }
  @media(max-width:670px){
.coma1 , .coma2{
  display: none;
}
  }
  @media(max-width:520px){
    .banner-section-left{
      width: 100%;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .banner-section h1{
      width: auto;
      font-size: 20px;
    }
    .banner-section{
      height: auto;
      padding: 20px;
  
    }
    .banner-section h1 span{
      font-size: 40px;
    }
    .banner-section{
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .persent-crds{
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .casestudy-right{
      padding: 20px;
    }
  
  }
  @media(max-width:900px){
    .about-section{
      padding-top: 50px;
    }
    .about-bnner2{
      margin-top: -50px;
      }
      .our-vision{
        margin-top: 50px;
        padding-bottom: 50px;
      }
  }

  .tabs-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tabs {
    display: flex;
    gap: 50px;
  }
  @media(max-width:425px){
    .about-who-we-are{
      margin-top: 1px;
    }
    .about-section{
      padding-bottom: 50px;
    }
    .newstatic{
      position: absolute;
      top: 650px;
     
    }
  }
  
  @media(max-width:1300px){
.apply{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 10px;
  padding: 10px;
}

  }
  .dropdown {
    position: relative;
   
  }
  .dropdown-content {
    width: auto;
    display: none;
    position: absolute;
    background-color: #3b3b3b;
    /* box-shadow: 0 8px 16px 0 rgba(106, 215, 229, 1); */
    z-index: 2;
    /* padding: 10px; */
    gap: 20px;
    margin-top: 1px;
    list-style: none;
    color: white;
  }
  .ul-containt{
    display: flex;
    flex-direction: row;
    color: white;
  }
  
  
  .ul-containt-wo{
    width: 180px;
    color: white;
  
  }
 
  
  .dropdown-content li:hover{
    background-color: rgba(106, 215, 229, 1);
  
  
  }
  .dropdown-content li{
    list-style: none;
    /* margin-top: 10px; */
    width: 100%;
   
  }
  .dropdown-content li a{
    text-decoration: none;
    color: white;
  
  
  }
  .dropdown-content li a:hover{
    /* background-color: #60BC0F; */
  }
  .dropdown:hover .dropdown-content,
  .dropdown-content:hover {
    display: block;
  }
  .dropdown:hover .dropdown-content,
  .dropdown-content:hover,
  .dropdown:hover {
    display: block;
    color: black;
  }
  .dropdown-content li {
    /* margin-top: 10px; */
    /* height: 30px !important; */
    padding: 10px;
    color: white !important;
  }
  .nav-contact{
    
  }
  .nav-contact img{
    height: 25px !important;
    float: right;
    margin-left: 30px;
    }
   .servies{
    display: flex;
    flex-direction: row;
   }
   .servies-left , .servies-right{
    width: 50%;
   }
   .servies-left h1{
    font-size:40px;
    font-weight: 600;
  font-family: 'Syne', sans-serif;
  text-align: center;
   }
   .servies-left h2 , .servies-left p{
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
   }
   .servies-left h2 {
    font-size: 24px;
   }
   .servies-left p{
    font-size: 15px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    
   }
   .servies-left{
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;
   }
   .servies-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   }
   .liktabs{
    text-decoration: none;
    color: rgb(0, 0, 0) !important;
    
   }

   .conf{
    width: 100%;
  display: flex;
  flex-direction: row;
  }
  .conf-text {
    width: 70%;
    /* padding: 20px; */
  }
  .conf-img{
    width: 30%;
  }
  /* .conf-text h1{
    font-size: 25px !important;
    font-family: 'Rubik', sans-serif;

    font-weight: 500 !important;
  
  } */
  .conf-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .csr-sub{
    width: 100%;
    padding-left: 140px;
    padding-right: 140px;
  }
  .PM-acd span{
    color: rgba(106, 215, 229, 1);
  }
  details {
    width: 100%;
      margin: 1rem auto;
      padding: 1.5rem;
      background-color: #fff;
      border-radius: 1rem;
      box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
     
    
    }
    .dtls{
      box-shadow: none !important;
      padding: 10px !important;
    }
    details summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      margin-bottom: 0;
      transition: margin-bottom 0.4s;
      position: relative;
    }
    details summary::-webkit-details-marker,
    details summary::marker {
      content: "";
      display: none;
    }
    details summary::after {
      content: "+";
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1;
      margin-right: 1rem;
      cursor: pointer;
      background-color: rgba(106, 215, 229, 1);
      color: white;
      padding: 0.75rem;
      display: grid;
      place-content: center;
      aspect-ratio: 1;
      line-height: 0;
      position: absolute;
      top: -0.5rem;
      right: -1.5rem;
      border-radius: 50%;
      margin-top: 15px;
    }
    details *:not(summary) {
      animation-name: fade;
      animation-duration: 0.4s;
    }
    
    details[open] summary {
      margin-bottom: 1.5rem;
    }
    details[open] summary::after {
      content: "−";
    }
    
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }




    
    .servies-acc{
      padding-left: 100px;
      padding-right: 100px;
    }
    .servies-heading{
      font-size:32px;
      font-weight: 600;
    font-family: 'Syne', sans-serif;
    /* text-align: center; */
     }
     .conf-text h1{
      font-size:30px;
      font-weight: 600;
    font-family: 'Syne', sans-serif;
  
     }
     .conf-text p{
      font-size: 19px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
      margin-top: 10px;
     }
     .conf-img img{
      height: 300px;

      /* height: 70%; */
     }
     .conf-img{
      display: flex;
      justify-content: center;
   

     }
     .wht{
      text-decoration: none;
      color: white;
     }
     .gp{
      display: flex;
      gap: 10px;
     }
     @media(max-width:1300px){
      .about-section2{
        padding-bottom: 80px !important;
      }
      .newstatic2{
        position: absolute;
        top: 380px;
      }
      .our-servies-section{
  margin-top: 250px !important;

      }
     }
     @media(max-width:1080px){
      .about-section2{
        padding-bottom: 100px !important;
      }
      .newstatic2{
        position: absolute;
        top: 430px;
      }
     }
     .bnnar{
    
      background-image: url(./Assets/rm373batch5-19.jpg);
      background-size: cover;
      background-position: top;
      padding-left: 50px;
      padding-top: 180px;
      padding-bottom: 150px;

     }
     .bnnar h1{
      font-size: 52px;
      font-weight: 600;
    font-family: 'Syne', sans-serif;
    color: white;
    
    }
    .bnnar p{
      font-size: 20px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
      color: white;
      width: 40%;
    }
    .bnnar button ,  .banner2 button{
      height: 40px;
      width: 130px;
      background: none;
      border: 1px solid white;
      color: white;
      font-size: 15px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
      border-radius: 20px;
    }
 .banner2{
  background-image: url(./Assets/medium-shot-man-wearing-vr-glasses.jpg);
  /* height: 100vh; */
  background-size: cover;
  /* background-position: center; */
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }
 .icon{
  height: 100px;
 }
 .banner2 h1{
  font-size: 52px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
color: white;
text-align: center;

}
.banner2 p{
 width: 61%;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  text-align: center;
  color: white;

}
.banner3{
padding-right: 50px;
background-size: cover;
background-position: center;
background-image: url(./Assets/search-engine-marketing-composition-design.jpg);
display: flex;
flex-direction: column;
align-items: end;
padding-top: 130px;
color: white;
padding-bottom: 130px;
}
.banner5{
padding-top: 50px;

  padding-bottom: 50px;
}
.banner3 h2{
  font-size: 52px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
color: white;
text-align: right;


}
.banner3 p{

  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  text-align: right;
  color: white;

}
.R-vission{

}
.R-vissioni-img{
  background-image: url(./Assets/vission.jpg);
  height: 60vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
padding-left: 200px;
padding-right: 200px;
}
.R-vissioni-img h1{
  font-size: 52px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
color: white;
}
.R-vissioni-img p{

  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
 text-align: center;
  color: white;

}
.R-vission-text{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
/* height: 50vh; */
}
.R-vission-text-left , .R-vission-text-right{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
}
.R-vission-text-left p , .R-vission-text-right p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
 text-align: center;
}
.icn{
margin-top: -35px;
}
.R-vission-text-left h1 , .R-vission-text-right h1{
  font-size: 30px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.R-mission{
  background-image: url(./Assets/mission.jpg);
  background-size: cover;
  background-position: center;
}

.R-mission h1{
  text-align: center;
}
.serviesSection{
  padding-left: 150px;
  padding-right: 150px;
}
.servies{
  height: 400px;
  border: 1px solid rgb(220, 219, 219);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
}
.serviesSection-text{
  padding: 20px;

}
.serviesSection-text h2{
  font-size: 30px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.serviesSection-text p{
  font-size: 18px;
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
}
.serviesSection-text button{
  height: 40px;
  width: 150px;
  background: none;
  border: 3px solid rgba(106, 215, 229, 1);
  color: rgba(106, 215, 229, 1);
  font-size: 15px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  border-radius: 20px;
}
.navlog{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
text-decoration: none;
margin-left: 20px;

}
.name{
  font-size: 20px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
margin-top: -15px;
color: #08BBBF;
}
.deco{
  text-decoration: none;

}
.footerlogo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
}
.clip{
  color: white;
  padding-left: 50px;
/* padding-right: 200px; */
  /* height: 400px; */
  width: 60%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #08bcbfbe;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}
.banner4{
  background-size: cover;
  background-position: center;
  /* padding-top: 100px; */
  background-image: url(./Assets/person-working-html-computer.jpg);
}
.clip{
  position: relative;
  z-index: 0;
}
.clip h1{
  width: 60%;
  font-size: 30px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.clip p{
  width: 75%;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.casestudy-ul{
  /* padding-left: 100px;
  padding-right: 100px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  gap: 20px;
}
.casestudy-ul ul{
  width: 20%;
  border: 1px solid rgb(228, 227, 227);
  font-size: 25px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
  margin-bottom: 20px;
  padding: 20px;
}
.casestudy-ul ul li{
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  list-style: none;
  margin-top: 10px;
}
.casestudy-points{
display: flex;
flex-direction: row;
}
.casestudy-points-left{
width: 50%;
padding: 50px;
}
.casestudy-points-right{
  width: 50%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;

}
.casestudy-points-right img{
  width: 70% !important;
}
.poindiv{
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(245, 245, 245);
  border-radius: 20px;
  border-right: 3px solid rgba(106, 215, 229, 1) !important;

}
.poindiv2{
  height: 250px;

}
.poindiv-point{
  width: 10%;
  height: 100% !important;
  background-color: rgba(106, 215, 229, 1);
  border-radius: 20px 0 0 20px ;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}
.poindiv-point p{
  font-size: 40px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.poindiv-text{
  width: 90%;
  height: auto;
  padding: 15px;

}
.poindiv-text h2{
  font-size: 20px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
margin-bottom: 10px;
}
.poindiv-text p{
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;

}
.casestudy-points-left h4{
  font-size: 30px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
margin-bottom: 10px;
}
.casestudy-points-left h5{
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;

}
.casestudy-result{
  display: flex;
  flex-direction: row;
}
.casestudy-result-left , .casestudy-result-right{
  width: 50%;
}
.casestudy-result-left h1{
  font-size: 30px;
  font-weight: 600;
font-family: 'Syne', sans-serif;

}
.casestudy-result-left{
  padding: 50px;
}
.casestudy-result-left p{
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  margin-top: 10px;
}
.casestudy-result-right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.casestudy-result-right img{
  width: 70%;

}
.casestudy-result-Conclusion{
  padding-left: 50px;
  padding-right: 50px;
}
.casestudy-result-Conclusion p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.product-cards{
  display: flex;
  flex-direction: row;
  padding-left: 150px;
  padding-right: 150px;
  justify-content: space-around;
}
.product-card{
  width: 40%;
  background-color: #f4f3f3;
  border-radius: 15px;
}
.product-card img{
  width: 100%;
  border-radius: 15px 15px 0 0;

}
.product-card h1{
  font-size: 25px;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.product-text{
  padding: 15px;
}
.product-card button{
  width: 150px;
  height: 40px;
  background: none;
  border: 1px solid #08BBBF;
  color: #08BBBF;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  border-radius: 20px;
  margin-top: 15px;

}
@media(max-width:1040px){
.bnnar p{
  width: 80%;

}
.R-vission-text-left , .R-vission-text-right{
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}
.R-vission-text-left p , .R-vission-text-right p{
  text-align: left;
}
.R-vissioni-img{
  padding-left: 50px;
padding-right: 50px;

height: auto;
padding-top: 100px;
padding-bottom: 100px;
}
}
@media(max-width:1000px){
  .R-vission-text{
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .R-vission-text-left , .R-vission-text-right{
    width: 100%;
    text-align: center;
  }
  .banner2 p{
 width: 80%;

  }
  .product-cards{
    padding-left: 50px;
    padding-right: 50px;
  }
  .product-card{
    width: 47%;
  }
  .servies-acc{
    padding-left: 30px;
    padding-right: 30px;
  }
  .banner4{

  }
  .clip{
    width: 90%;
  }
  .casestudy-ul{
    padding-left: 30px;
    padding-right: 30px;
  }
  .casestudy-ul ul{
    width: 25%;
}

}
@media(max-width:840px){
  .conf-img img{
    height: 200px;

   }

}
@media(max-width:650px){
.conf{
  flex-direction: column;
}
.conf-text , .conf-img{
  width: 100%;
}
.clip{
  width: 95%;
}
.clip h1{
  width: 85%;
  font-size: 25px;
}
.clip p{
  width: 85%;
}
.casestudy-ul{
  padding-left: 30px;
  padding-right: 30px;
}
}
@media(max-width:700px){
  .product-cards{
    padding-left: 30px;
    padding-right: 30px;
  }
  .servies-heading{
    font-size:30px;

  }
}

@media(max-width:550px){
  .bnnar{
    width: 100%;
    padding-left: 30px;
    background-position: left;
    padding-top: 50px;
    padding-bottom: 50px;

  }
  .banner2{
    padding-left: 30px;
    padding-right: 30px;
  }
  .banner2 h1 , .banner2 p{
    text-align: left;
  }
  .banner2 p{
    width: 100%;
   
     }
     .banner3{
      background-position: right;
      padding-top: 150px;
padding-left: 30px;
padding-bottom: 50px;
     }
     .banner3 h2{
      width: 100%;
      font-size: 32px;
text-align: left !important;

      
     }
     .banner5{
      background-position: left;
     }
     .banner3 p{
text-align: left;
width: 100%;

      
     }
     .product-cards{
      padding-left: 30px;
      padding-right: 30px;
      flex-direction: column;
      gap: 20px;
    }
    .product-card{
      width: 100%;
    }
}
.rproject{
  text-align: center;
  font-size: 40px !important;
}
@media(max-width:1380px){
  .poindiv{
    height: 250px;
  }
}
@media(max-width:1380px){
  .poindiv{
    height: 300px;
  }
}
@media(max-width:1080px){
  .casestudy-points{
    display: flex;
    flex-direction: column;
    }
    .casestudy-points-left{
      width: 100%;
      padding: 50px;
      }
      .casestudy-points-right{
      width: 100%;

      }
      .casestudy-result{
        flex-direction: column;
      }
      .casestudy-result-left , .casestudy-result-right{
        width: 100%;
      }
}
@media(max-width:600px){
  .casestudy-points-left{
    width: 100%;
    padding: 30px;
    }
  .poindiv{
    height: 400px;
  }
}
@media(max-width:400px){
  .poindiv{
    height: 450px;
  }
}
.caselink{
  text-decoration: none;
}

.f1 {
  width: 20%;
}

.f2{
 width: 20%;
}
.f3{
  width: 20%;
}
.f4{
  width: 2 0%;
}
.social{
  display: flex;
  flex-direction: row;
}
.footer-top{
  gap: 50px;
}
@media(max-width:1100px){
  .footer-top{

  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}
}
@media(max-width:870px){

  .footer-top{
    display: flex;
    flex-direction: column;
  }
  .f1 {
    width: 100%;
  }
  
  .f2{
   width: 100%;
  }
  .f3{
    width: 100%;
  }
  .f4{
    width: 100%;
  }
}

@media(max-width:900px){
  .casestudy-ul{
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
  }
  .casestudy-ul ul{
    width: 100%;
  }
}
@media(max-width:1100px){
  .footer-top{
    padding-left: 30px;
    padding-right: 30px;
  }
  .social{
    display: flex;
    flex-direction: row;
    gap: 1px;
  }
  .social img{
    height: 50px;
  }
}



.banner5{
  background-image: url(./Assets/contactus.png);
}

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  width: 90%;
  height: 250px;
}
.blog-card a {
  color: inherit;
}
.blog-card a:hover {
  color: #08BBBF;
}
.blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
}
.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}
.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.blog-card .details a {
text-decoration: none;
}
.blog-card .details ul li {
  display: inline-block;
}
.blog-card .details .author:before {
  
  margin-right: 10px;
  /* content: ""; */
}
.blog-card .details .date:before {
  
  margin-right: 10px;
  /* content: ""; */
}
.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  /* content: ""; */
  margin-right: 10px;
}
.blog-card .details .tags li {
  margin-right: 2px;
}
.blog-card .details .tags li:first-child {
  margin-left: -4px;
}
.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}
.blog-card .description h1,
.blog-card .description h2 {

}
.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1rem;
}
.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}
.blog-card .description .read-more {
  text-align: right;
}
.blog-card .description .read-more a {
  color: #08BBBF;
  display: inline-block;
  position: relative;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: 'Rubik', sans-serif !important;
  text-decoration: none;
}
.blog-card .description .read-more a:after {
  /* content: ""; */
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
/* .blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
} */
.blog-card p {
  position: relative;
  margin: 1rem 0 0;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: 'Rubik', sans-serif !important;
}
.blog-card p:first-of-type {
  margin-top: 1.25rem;
}
.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #08BBBF;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
.blog-card:hover .details {
  left: 0%;
}
@media (max-width: 1170px) {
  .blog-card{
    height: 300px;
  }
}
@media (min-width: 900px) {
  .blog-card {
    flex-direction: row;
    /* max-width: 700px; */
    height: auto !important;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blog-card.alt {
    flex-direction: row-reverse;
  }
  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  .blog-card.alt .details {
    padding-left: 25px;
  }
}

.photo{
  background-image: url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-2.jpg);
}
.description h1{
  font-size: 30px !important;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.description h2{
  font-size: 10px !important;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}
.description p{
  font-size: 15px !important;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.ModalHeader{
  font-size: 30px !important;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.blogimg{
  height: 300px;
  width: 100%;
}
.ModalBody h1{
  font-size: 30px !important;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.servies-acc-heading h2{ 
  font-size: 40px !important;
  font-weight: 600;
font-family: 'Syne', sans-serif;
}
.textarea{
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
}
.new-blog-cards{
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-right: 30px;
  gap: 20px;
}
@media(max-width:768px){
  .new-blog-cards{
    flex-direction: column ;
    height: auto !important;
  }
  }
  .cont{
    display: flex;
    gap: 5px;
  }

  


  .input-field {
    position: relative;
    width: 100%;
    height: 44px;
    /* line-height: 44px; */
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #d3d3d3;
    transition: 0.2s all;
    cursor: text;
  }
  .input {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.5rem 0;
    border-bottom: 2px solid #d3d3d3;
    box-shadow: none;
    color: #111;
  }
  .input:invalid {
    outline: 0;
    /* color: #ff2300;
   border-color: #ff2300; */
  }
  .input:focus,
  .input:valid {
    border-color: rgba(106, 215, 229, 1);
  }
  .input:focus~label,
  .input:valid~label {
    font-size: 14px;
    top: -24px;
    color: rgba(106, 215, 229, 1);
  }
  .meetpeople{
    font-size: 32px !important;
  }
  .servies-heading2{
    font-size: 25px;
  }
  .weoffer{
    font-size: 40px !important;

  }
  #Blogs{
    font-size: 40px !important;

  }
  .active{
    color: #08BBBF !important;
  
  }


  .marquee {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    
    overflow: hidden;
  
    white-space: nowrap;
  }
  
  .marquee--inner {
    display: block;
    /* width: 200%; */
    position: absolute;
    animation: marquee 10s linear infinite;
   
  }
  .span {
    float: left;
    width: 50%;
    overflow: hidden;
  }
  
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -50%;
    }
  }
  .sss{
    display: flex;
    gap: 100px;
  }
  .swiper-pagination-bullet {
    background-color: #08BBBF !important; 
}


.swiper-pagination-bullet-active {
    background-color: #08BBBF !important; 
}
.swiper-button-next,
.swiper-button-prev {
    color: #08BBBF !important; 
}
/* Add your existing styles here */

.accordion {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  padding: 10px 18px 18px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 23px;
  font-weight: 700;
font-family: 'Syne', sans-serif;

  transition: 0.4s;

}

.active,
.accordion:hover {
  background-color: #ffffff;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
#plus{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;

}
.pluseicon{

  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  font-size: 25px;

}